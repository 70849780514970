#carousel {
  width: 100%;
}

#carousel .container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#carousel .container .item {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 0 15vw;
}

#carousel .container .item .title {
  color: white;
  text-align: center;
  font-size: 56px;
  max-width: 700px;
  text-transform: uppercase;
}

#carousel .container .item .subtitle {
  color: white;
  font-size: 32px;
  max-width: 700px;
  text-align: center;
  text-transform: uppercase;
}

#carousel .container .item #ctaForm {
  margin-left: 8rem;
}

/* Estilo para todos os .item que NÃO são o primeiro */
#carousel .container .item.not-first-item {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

/* Estilo para o título dos itens que NÃO são o primeiro */
#carousel .container .item.not-first-item .title {
  color: white;
  text-align: center;
  font-size: 56px;
  max-width: 700px;
}

/* Estilo para o subtítulo dos itens que NÃO são o primeiro */
#carousel .container .item.not-first-item .subtitle {
  color: white;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
}

#carousel .container .item.not-first-item #ctaForm {
  margin-left: 0;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #carousel .container .item {
    padding: 0 2.5vw;
  }

  #carousel .container .item .title {
    font-size: 30px;
    max-width: 100%;
    margin-top: auto;
  }

  #carousel .container .item .subtitle {
    font-size: 20px;
    max-width: 100%;
  }

  #carousel .container .item #ctaForm {
    margin: 0 auto 4rem;
  }

  /* Estilo para o título dos itens que NÃO são o primeiro */
  #carousel .container .item.not-first-item .title {
    font-size: 30px;
    max-width: 100%;
    margin-top: 0;
  }

  /* Estilo para o subtítulo dos itens que NÃO são o primeiro */
  #carousel .container .item.not-first-item .subtitle {
    font-size: 20px;
  }

  #carousel .container .item.not-first-item #ctaForm {
    margin: 0 auto;
  }
}