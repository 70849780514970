#ctaForm {
    background-color: #FF7F3A;
    font-weight: 700;
    padding: 1.2rem 0;
    width: 25%;
    height: fit-content;
    border-radius: 2000px;
}

#ctaForm a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaForm p {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaForm {
        width: 55%;
    }

    #ctaForm p {
        font-size: 17px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaForm {
        width: 75%;
    }

    #ctaForm p {
        font-size: 14px;
    }
}