#header .header2 {
    background-color: #FCFCFC;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}

#header .header2 .container .line {
    height: 3px;
    width: 20%;
    margin: 1rem 0;
    background-color: #FF7F3A;
}

#header .header2 .container .text {
    text-align: center;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

#header .header3 .container .text1 {
    font-size: 30px;
    text-align: center;
}

#header .header3 .container .line {
    height: 3px;
    width: 15%;
    margin: 1rem 0;
    background-color: black;
}

#header .header3 .container .subtitle {
    text-align: center;
    font-size: 20px;
}

#header .header3 .container .gallery {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 2rem;
    width: 70%;
}

#header .header3 .container .gallery .left {
    height: 100%;
}

#header .header3 .container .gallery .left img {
    height: 100%;
    display: block;
}

#header .header3 .container .gallery .right {
    background-color: #FBFBFB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    border-radius: 10px;
}

#header .header3 .container .gallery .right ul {
    margin-left: 1rem;
}

#header .header3 .container .gallery .right ul li {
    list-style-type: disc;
    font-size: 16px;
}

#header .header3 .container .gallery .right #ctaForm {
    width: 50%;
}

#header #wpp {
    position: fixed;
    right: 0vw;
    top: 90vh;
}

#header #wpp a figure img {
    width: 70%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header3 .container .text1 {
        font-size: 23px;
    }

    #header .header3 .container .subtitle {
        font-size: 17px;
    }

    #header .header3 .container .gallery {
        grid-template-columns: 1fr;
        width: 100%;
    }

    #header .header3 .container .gallery .right ul li {
        font-size: 15px;
    }

    #header .header3 .container .gallery .right #ctaForm {
        width: 60%;
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header3 .container .text1 {
        font-size: 16px;
    }

    #header .header3 .container .subtitle {
        font-size: 14px;
    }

    #header .header3 .container .gallery .right ul li {
        font-size: 14px;
    }

    #header .header3 .container .gallery .right #ctaForm {
        width: 90%;
    }
}