#formPage .formPage1 .container {
  margin-top: -12rem;
}

#formPage .formPage1 .container .final {
  text-align: center;
  width: 100%;
}

#formPage .formPage1 .container .final img {
  width: 80%;
}

#formPage .formPage2 .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#formPage .formPage2 .container .title {
  font-size: 48px;
}

#formPage .formPage2 .container .line {
  height: 3px;
  width: 15%;
  margin: 1rem 0;
  background-color: #FF7F3A;
  margin-top: -1rem;
}

#formPage .formPage2 .container .text {
  text-align: center;
  color: #4B5668;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #formPage .formPage1 .container {
    margin-top: 0;
  }

  #formPage .formPage1 .container .final img {
    width: 100%;
  }

  #formPage .formPage2 .container .title {
    font-size: 36px;
  }

  #formPage .formPage2 .container .line {
    width: 25%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #formPage .formPage2 .container .title {
    font-size: 24px;
  }
}