#about .about1 {
  background-color: #F4F3F3;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

#about .about1 .container .subtitle {
  text-align: center;
  font-size: 20px;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: 70% 1fr;
  gap: 2rem;
  width: 70%;
}

#about .about1 .container .gallery .left {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
}

#about .about1 .container .gallery .left ul {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .gallery .left ul li {
  list-style-type: disc;
  font-size: 16px;
  max-width: 700px;
}

#about .about1 .container .gallery .left #ctaForm {
  width: 50%;
}

#about .about1 .container .gallery .right {
  height: 100%;
}

#about .about1 .container .gallery .right img {
  height: 100%;
  display: block;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

#about .about2 .container .subtitle {
  text-align: center;
  font-size: 20px;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 2rem;
  width: 70%;
}

#about .about2 .container .gallery .left {
  height: 100%;
}

#about .about2 .container .gallery .left img {
  height: 100%;
  display: block;
}

#about .about2 .container .gallery .right {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
}

#about .about2 .container .gallery .right ul {
  margin-left: 1rem;
}

#about .about2 .container .gallery .right ul li {
  list-style-type: disc;
  font-size: 16px;
}

#about .about2 .container .gallery .right #ctaForm {
  width: 50%;
}

#about .about3 {
  background-color: #F4F3F3;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

#about .about3 .container .subtitle {
  text-align: center;
  font-size: 20px;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: 70% 1fr;
  gap: 2rem;
  width: 70%;
}

#about .about3 .container .gallery .left {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
}

#about .about3 .container .gallery .left ul {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

#about .about3 .container .gallery .left ul li {
  list-style-type: disc;
  font-size: 16px;
  max-width: 700px;
}

#about .about3 .container .gallery .left #ctaForm {
  width: 50%;
}

#about .about3 .container .gallery .right {
  height: 100%;
}

#about .about3 .container .gallery .right img {
  height: 100%;
  display: block;
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

#about .about4 .container .subtitle {
  text-align: center;
  font-size: 20px;
}

#about .about4 .container .gallery {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 2rem;
  width: 70%;
}

#about .about4 .container .gallery .left {
  height: 100%;
}

#about .about4 .container .gallery .left img {
  height: 100%;
  display: block;
}

#about .about4 .container .gallery .right {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
}

#about .about4 .container .gallery .right ul {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about4 .container .gallery .right ul li {
  list-style-type: disc;
  font-size: 16px;
}

#about .about4 .container .gallery .right #ctaForm {
  width: 50%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .subtitle {
    font-size: 17px;
  }

  #about .about1 .container .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #about .about1 .container .gallery .left ul li {
    font-size: 15px;
  }

  #about .about1 .container .gallery .left #ctaForm {
    width: 60%;
    margin: 0 auto;
  }

  #about .about1 .container .gallery .right {
    order: -1;
  }

  #about .about2 .container .subtitle {
    font-size: 17px;
  }

  #about .about2 .container .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #about .about2 .container .gallery .right ul li {
    font-size: 15px;
  }

  #about .about2 .container .gallery .right #ctaForm {
    width: 60%;
    margin: 0 auto;
  }

  #about .about3 .container .subtitle {
    font-size: 17px;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #about .about3 .container .gallery .left ul li {
    font-size: 15px;
  }

  #about .about3 .container .gallery .left #ctaForm {
    width: 60%;
    margin: 0 auto;
  }

  #about .about3 .container .gallery .right {
    order: -1;
  }

  #about .about4 .container .subtitle {
    font-size: 17px;
  }

  #about .about4 .container .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #about .about4 .container .gallery .right ul li {
    font-size: 15px;
  }

  #about .about4 .container .gallery .right #ctaForm {
    width: 60%;
    margin: 0 auto;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .subtitle {
    font-size: 14px;
  }

  #about .about1 .container .gallery .left ul li {
    font-size: 14px;
  }

  #about .about1 .container .gallery .left #ctaForm {
    width: 90%;
  }

  #about .about2 .container .subtitle {
    font-size: 14px;
  }

  #about .about2 .container .gallery .right ul li {
    font-size: 14px;
  }

  #about .about2 .container .gallery .right #ctaForm {
    width: 90%;
  }

  #about .about3 .container .subtitle {
    font-size: 14px;
  }

  #about .about3 .container .gallery .left ul li {
    font-size: 14px;
  }

  #about .about3 .container .gallery .left #ctaForm {
    width: 90%;
  }

  #about .about4 .container .subtitle {
    font-size: 14px;
  }

  #about .about4 .container .gallery .right ul li {
    font-size: 14px;
  }

  #about .about4 .container .gallery .right #ctaForm {
    width: 90%;
  }
}