#menu .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
}

#menu .container .logo img {
    width: 10%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        padding: .5rem 0;
    }

    #menu .container .logo img {
        width: 20%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .logo img {
        width: 35%;
    }
}