#content .content1 {
  background-color: #F4F3F3;
}

#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

#content .content1 .container .line {
  height: 3px;
  width: 10%;
  margin: 1rem 0;
  background-color: black;
  margin-top: -1rem;
}

#content .content1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 2rem 1rem;
  width: 80%;
}

#content .content1 .container .gallery.second {
  grid-template-columns: repeat(2, 1fr);
  width: 60%;
}

#content .content1 .container .gallery .item {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
}

#content .content1 .container .gallery .item .top {
  display: grid;
  grid-template-columns: 10% 1fr;
  align-items: center;
  gap: 1rem;
}

#content .content1 .container .gallery .item .top .item_title {
  font-weight: 600;
}

#content .content1 .container .gallery .item .item_text {
  color: #404040;
  font-size: 16px;
}

#content .content2 {
  background: url("../../assets/content2_bg.png") no-repeat center center/cover;
}

#content .content2 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 6rem 0 12rem;
}

#content .content2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content2 .container .left .title {
  text-align: left;
  color: white;
}

#content .content2 .container .left .line {
  height: 3px;
  width: 25%;
  margin: 1rem 0;
  background-color: #FF7F3A;
}

#content .content2 .container .left .text {
  color: white;
  font-weight: 300;
  max-width: 600px;
}

#content .content2 .container .left #ctaForm {
  width: 40%;
}

#content .content2 .container .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
}

#content .content2 .container .right .name {
  font-size: 24px;
}

#content .content2 .container .right .line {
  height: 2px;
  width: 20%;
  margin: 1rem 0;
  background-color: #FFFFFF;
  margin-top: -.5rem;
}

#content .content2 .container .right .line.full {
  width: 70%;
  margin-top: 1rem;
}

#content .content2 .container .right ul {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin-top: -1rem;
}

#content .content2 .container .right ul li {
  list-style-type: disc;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 16px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container .gallery {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #content .content1 .container .gallery.second {
    grid-template-columns: 1fr;
    width: 100%;
  }

  #content .content1 .container .gallery .item .top {
    grid-template-columns: 8% 1fr;
  }

  #content .content1 .container .gallery .item .item_text {
    font-size: 15px;
  }

  #content .content2 {
    background: url("../../assets/mobile/content2_bg.png") no-repeat center center/cover;
  }

  #content .content2 .container {
    grid-template-columns: 1fr;
    gap: 4rem;
    padding: 6rem 0;
  }

  #content .content2 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #content .content2 .container .left .title {
    text-align: center;
  }

  #content .content2 .container .left .line {
    margin: 1rem auto;
  }

  #content .content2 .container .left .text {
    max-width: 100%;
    text-align: center;
  }

  #content .content2 .container .left #ctaForm {
    width: 55%;
    margin: 0 auto;
  }

  #content .content2 .container .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
  }

  #content .content2 .container .right .name {
    font-size: 20px;
    text-align: center;
  }

  #content .content2 .container .right .line {
    margin: -.5rem auto 0;
  }

  #content .content2 .container .right .line.full {
    width: 70%;
    margin-top: 1rem;
  }

  #content .content2 .container .right ul li {
    font-size: 15px;
    margin: 0 auto;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container .gallery .item .item_text {
    font-size: 14px;
  }

  #content .content2 .container .right .name {
    font-size: 16px;
  }

  #content .content2 .container .right ul li {
    font-size: 14px;
  }

  #content .content2 .container .left #ctaForm {
    width: 75%;
  }
}