#form {
    color: black;
    border-radius: 10px;
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

#form label {
    margin-top: .3rem;
}

#form span {
    color: red;
}

#form input,
#form textarea {
    border-radius: 5px;
    padding: .5rem 5px;
    background-color: #F7FAFD;
    border: 1px solid #FF7F3A;
    resize: none;
    font-size: 18px;
}

#form input {
    padding: .8rem 5px;
}

#form .check {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}

#form .check.mobile {
    display: none;
}

#form .form-div1 {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div2 {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div2 button {
    background-color: #FF7F3A;
    cursor: pointer;
    margin-top: .5rem;
    text-transform: uppercase;
    padding: 1rem 0;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #form {
        width: 80%;
        grid-template-columns: 1fr;
        gap: .8rem;
    }

    #form .check {
        display: none;
    }

    #form .check.mobile {
        display: flex;
    }

    #form .form-div2 button {
        font-size: 17px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #form {
        width: 95%;
    }

    #form .form-div2 button {
        font-size: 15px;
    }
}