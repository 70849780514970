#success {
    background: url("../../assets/banner_topo_1.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: center;
}

#success .container .title {
    color: white;
    text-align: left;
    max-width: 900px;
    font-size: 56px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #success {
        background: url("../../assets/mobile/banner_topo_1.png") no-repeat center 0;
        background-size: cover;
        height: 90vh;
    }

    #success .container .title {
        text-align: center;
        max-width: 100%;
        font-size: 43px;
        margin: auto 0 5rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #success .container .title {
        text-align: center;
        max-width: 100%;
        font-size: 30px;
        margin: auto 0 3rem;
    }
}