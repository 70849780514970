#footer .footer1 .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    align-items: center;
}

#footer .footer1 .container .logo img {
    width: 60%;
}

#footer .footer1 .container .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .item .item_title {
    font-weight: bold;
    font-size: 16px;
}

#footer .footer1 .container .item .bottom {
    display: grid;
    grid-template-columns: 5% 1fr;
    gap: .5rem;
}

#footer .footer1 .container .item .bottom .item_text {
    color: #4B5668;
    font-size: 14px;
    max-width: 350px;
}

#footer .footer2 {
    background-color: #0A4189;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    color: white;
    font-size: 14px;
    font-weight: 300;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        grid-template-columns: 1fr;
    }

    #footer .footer1 .container .logo img {
        width: 30%;
    }

    #footer .footer1 .container .item .item_title {
        text-align: center;
    }

    #footer .footer1 .container .item a {
        text-align: center;
    }

    #footer .footer1 .container .item .plural {
        display: flex;
        justify-content: center;
        gap: 2rem;
    }

    #footer .footer1 .container .item .bottom {
        display: grid;
        grid-template-columns: 26px 1fr;
        align-items: center;
        gap: .5rem;
    }

    #footer .footer1 .container .item .bottom .item_text {
        max-width: 100%;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    #footer .footer2 .container p {
        font-size: 14px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .logo img {
        width: 35%;
    }

    #footer .footer1 .container .item .bottom {
        grid-template-columns: 18px 1fr;
    }

    #footer .footer1 .container .item .plural {
        gap: 1rem;
    }
}